<template>
  <web-link
    v-model="webLinkList"
    @input="emitValue"
  ></web-link>
</template>

<script>
import webLink from "@/views/adManagement/createAd/components/assetFeed/webLink.vue";
export default {
  components: {
    webLink,
  },
  props:{
      value:{
          type:Array,
          defalue:()=>[]
      }
  },
  data() {
    return {
        webLinkList: [],
    }
  },
  methods:{
      emitValue(){
          this.$emit('input',this.webLinkList)
      }
  },
  watch: {
    value: {
      handler() { 
        this.webLinkList = [...this.value];
      },
      deep:true,
      immediate:true
    },

  },
};
</script>

<style>
</style>